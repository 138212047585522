.form-group {
    .form-control {
    -webkit-box-shadow: none;
    border-bottom: 1px;
    border-style: none none solid none;
    border-radius: 0;
    border-color: #fff;
    background-color: transparent;
    color: #fff;
    box-shadow: none;
    }
    .form-control:focus {
    background-color: transparent!important;
    color: #fff;
    box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
}
}
